// types
import { createSlice } from '@reduxjs/toolkit'
// import { useSelector } from "react-redux";

// initial state
const initialState = {
  usersList: [],
  // fleetUsersList: [],
  emailVerified: null,
  numberVerified: null,
  buddyList: [],
  notificationList: [],
  user: null,
  frProUser: null,
  frProUserProfile: null,
  authorizationToken: null,
  businessUser: null,
  searchFriendsResults: [],
  tempValToLocalStorage: [],
  data: {},
  workExperienceCompanyId: '',
  workExperienceVehicleId: '',
  workExperienceRouteId: '',
  vehiclesIds: [],
  routeIds: [],
  followers: [],
  following: [],
  otherUserInfo: {},
  targetUserFollowers: [],
  targetUserFollowing: [],
  targetUserBuddyList: [],
  profileImage: null,
}

// ==============================|| SLICE - Followers ||============================== //

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset: () => initialState,
    getUsersList(state, action) {
      // state.followersList = action.payload.followersList;
      return state.usersList
    },
    setInitialValues(state) {
      state.frProUser = {
        updateProfileForm: {
          workExperienceStorage: [],
          terminalDetails: [],
          wareHouseDetails: [],
        },
      }
    },
    setUsersList(state, action) {
      console.log('Action palylod', action.payload)
      state.usersList = state?.usersList?.push(action.payload.usersList)
    },
    // getFleetUsersList(state, action) {
    //   // state.followersList = action.payload.followersList;
    //   return state.fleetUsersList;
    // },
    // setFleetUsersList(state, action) {
    //   console.log("Action palylod", action.payload);
    //   state.fleetUsersList = state?.fleetUsersList?.push(action.payload.fleetUsersList);
    // },
    getUser(state, action) {
      // state.followersList = action.payload.followersList;
      return state.user
    },
    setEmailVerificationStatus(state, action) {
      state.emailVerified = action.payload
    },
    setMobileVerificationStatus(state, action) {
      state.numberVerified = action.payload
    },
    getfrProUser(state, action) {
      // state.followersList = action.payload.followersList;
      return state.frProUser
    },
    getFrProUserProfile(state, action) {
      // state.followersList = action.payload.followersList;
      return state?.frProUserProfile
    },
    getFrProUserPersonalInfo(state, action) {
      // state.followersList = action.payload.followersList;
      return state?.frProUser?.updateProfileForm?.personalInfo
    },
    setUser(state, action) {
      console.log('Action palylod', action.payload)
      state.user = action.payload.user
    },
    setFrProUser(state, action) {
      console.log(
        'Action palylod..........................................................',
        action.payload
      )
      state.frProUser = action.payload.frProUser
      // state.frProUser = action.payload;
      // console.log("testUser2 check: ", state.frProUser)
    },
    setFrProUserProfile(state, action) {
      console.log(
        'Action palylod..........................................................',
        action.payload
      )
      // let frProUserVal = Object.assign({}, state.frProUserProfile, { frProUserVal:  })
      state.frProUserProfile = action.payload.frProUserProfile
      // state.frProUser = action.payload;
      // console.log("testUser2 check: ", state.frProUser)
    },
    setFrProUserEmailVerification(state, action) {
      console.log(
        'Action palylod..........................................................',
        action.payload
      )
      // state.frProUser.emailVerificationList = action.payload.emailVerificationList;
      // state.frProUser = action.payload;
      console.log('testUser2 check: ', action.payload.emailVerificationList)
      let frProUserVal = Object.assign({}, state.frProUser, {
        emailVerificationList: action.payload.emailVerificationList,
      })
      state.frProUser = frProUserVal
    },
    setFrProUserPersonalInfo(state, action) {
      console.log('Action palylod', action.payload)
      // state.frProUser.updateProfileForm.personalInfo = action?.payload?.personalInfo;
      // state.frProUser = {...state?.frProUser,updateProfileForm:{...state.frProUser.updateProfileForm,person}};
      // console.log("testUser2 check: ", state.frProUser)

      if (state && state.frProUser && state.frProUser.updateProfileForm) {
        state.frProUser.updateProfileForm.personalInfo =
          action?.payload?.personalInfo
      }
    },
    getauthorizationToken(state, action) {
      // state.followersList = action.payload.followersList;
      return state.authorizationToken
    },
    setauthorizationToken(state, action) {
      console.log('Action palylod', action.payload)
      state.authorizationToken = action.payload.authorizationToken
    },
    setBusinessUser(state, action) {
      console.log('Action palylod', action.payload)
      state.businessUser = action.payload
    },

    getSearchFriends(state, action) {
      return state.searchFriendsResults
    },

    setSearchFriends(state, action) {
      state.searchFriendsResults = action.payload.searchFriendsResults
    },
    getTempValToLocalStorage(state, action) {
      return state.tempValToLocalStorage
    },

    setTempValToLocalStorage(state, action) {
      let valToLocalStorage = [] //state?.tempValToLocalStorage;
      valToLocalStorage.push(action.payload.pushTempValToLocalStorage)
      state.tempValToLocalStorage = valToLocalStorage
    },
    // setWorkExperienceStorage(state, action) {
    //   console.log(action.payload.workExperienceStorage);
    //   state.workExperienceStorage = action.payload.workExperienceStorage;
    // },
    setDataofObj(state, action) {
      state.data = action.payload
    },
    setWorkExperienceCompanyId(state, action) {
      state.workExperienceCompanyId = action.payload
    },
    setWorkExperienceVehicleId(state, action) {
      state.workExperienceVehicleId = action.payload
    },
    setWorkExperienceRouteId(state, action) {
      state.workExperienceRouteId = action.payload
    },

    setVehiclesIds(state, action) {
      state.vehiclesIds = action.payload
    },
    setRoutesids(state, action) {
      state.routeIds = action.payload
    },
    setBuddyList(state, action) {
      state.buddyList = action.payload.buddyList
    },
    getBuddyList(state, action) {
      return state.buddyList
    },
    setNotificationList(state, action) {
      console.log('Updating notifications', action.payload.notificationList)
      state.notificationList = action.payload.notificationList
    },
    getNotificationList(state, action) {
      return state.notificationList
    },
    setLoggedInUserFollowersList(state, action) {
      console.log('setLoggedInUserFollowersList', action.payload.followersList)
      state.followers = action.payload.followersList
    },
    getLoggedInUserFollowersList(state, action) {
      return state.buddyList
    },
    setLoggedInUserBuddiesList(state, action) {
      console.log('getLoggedInUserBuddiesList', action.payload.buddiesList)
      state.buddyList = action.payload.buddiesList
    },
    getLoggedInUserBuddiesList(state, action) {
      return state.buddyList
    },

    setLoggedInUserFollowingList(state, action) {
      console.log('setLoggedInUserFollowingList', action.payload.followingList)
      state.following = action.payload.followingList
    },
    getLoggedInUserFollowingList(state, action) {
      return state.following
    },

    setOtherUserInfo(state, action) {
      console.log('setOtherUserInfo', action.payload.otherUserInfo)
      state.otherUserInfo = action.payload.otherUserInfo
    },
    getOtherUserInfo(state, action) {
      return state.otherUserInfo
    },

    setTargetUserFollowersList(state, action) {
      console.log('setLoggedInUserFollowersList', action.payload.followersList)
      state.targetUserFollowers = action.payload.targetUserFollowersList
    },
    getTargetUserFollowersList(state, action) {
      return state.targetUserFollowers
    },
    setTargetUserFollowingList(state, action) {
      console.log('setLoggedInUserFollowingList', action.payload.followingList)
      state.targetUserFollowing = action.payload.targetUserFollowingList
    },
    getTargetUserFollowingList(state, action) {
      return state.targetUserFollowing
    },
    setTargetUserBuddiesList(state, action) {
      console.log('setLoggedInUserFollowingList', action.payload.buddiesList)
      state.targetUserBuddyList = action.payload.targetUserBuddyList
    },
    getTargetUserBuddiesList(state, action) {
      return state.targetUserBuddyList
    },
    setProfileImage(state, action) {
      state.profileImage = action.payload
    },
  },
})
// export const userData = useSelector((state)=>state.Users)
export default users.reducer

export const {
  setProfileImage,
  getLoggedInUserBuddiesList,
  setLoggedInUserBuddiesList,
  setTargetUserBuddiesList,
  getTargetUserBuddiesList,
  setTargetUserFollowersList,
  getTargetUserFollowersList,
  setTargetUserFollowingList,
  getTargetUserFollowingList,
  getNotificationList,
  setNotificationList,
  setBuddyList,
  getSearchFriends,
  setSearchFriends,
  setBusinessUser,
  getUser,
  getUsersList,
  setUser,
  setUsersList,
  setFrProUser,
  setFrProUserProfile,
  getfrProUser,
  getFrProUserProfile,
  setFrProUserPersonalInfo,
  getFrProUserPersonalInfo,
  setauthorizationToken,
  getauthorizationToken,
  getTempValToLocalStorage,
  setTempValToLocalStorage,
  setEmailVerificationStatus,
  setMobileVerificationStatus,
  // setWorkExperienceStorage,
  setDataofObj,
  setWorkExperienceCompanyId,
  setWorkExperienceVehicleId,
  setWorkExperienceRouteId,
  setVehiclesIds,
  setRoutesids,
  setInitialValues,
  setFrProUserEmailVerification,
  setLoggedInUserFollowersList,
  setLoggedInUserFollowingList,
  getLoggedInUserFollowersList,
  getLoggedInUserFollowingList,
  setOtherUserInfo,
  getOtherUserInfo,
  reset: resetUser,
} = users.actions
