import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { UsersApis } from '../../../API/Login/UsersApi.js'
import Loader from '../../../Components/Loader/Loader.js'
import Toasts from '../../../Components/Toasts/Toasts.js'
import { StropheContext } from '../../ChatClient/index.js'
import NotificationImg from './NotificationImg.jsx'
// import { UseloadChatClient } from '../ChatClient/Chatclient';
const NotificationsPopUp = (props) => {
  let loogedInUser = JSON.parse(localStorage.getItem('user'))
  const stropheContext = useContext(StropheContext)
  console.log('Notification strophe connection context', stropheContext)
  const [notificationList1, setnotificationList1] = useState(
    loogedInUser?.notificationsList
  )
  const navigate = useNavigate()
  const [reqAccepted, setreqAccepted] = useState(false)
  const [reqRejected, setreqRejected] = useState(false)
  const [toasts, setToasts] = useState(null)
  // let notificationList1 = loogedInUser?.notificationsList
  console.log('Notification', notificationList1)
  const { frProUser } = useSelector((state) => state.Users)
  const [errorsForNotification, seterrorsForNotification] = useState(null)
  const [showLoading, setshowLoading] = useState(false)
  // const { notificationList, approveBuddyReq } = UseloadChatClient();

  const [filteredNotifications, setFilteredNotifications] = useState([])

  const checkerArray = [1, 1, 1, 1, 1, 1]
  console.log(
    'notificationList from use hooks',
    stropheContext?.notificationList
  )

  const approveBuddyReqFunc = (event) => {
    console.log('Approve buddy req', event?.currentTarget?.name)
    stropheContext?.approveBuddyReq(event?.currentTarget?.name)
    console.log('Approve buddy req', event?.currentTarget?.name)
    let obj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      // "requestFromUser": event?.currentTarget?.name,
      requestFromUser: event?.currentTarget?.name?.split('@')[0],
      requestTime: new Date().toISOString(),
    }
    UsersApis.userBuddyRequestApproved(obj)
      .then((res) => {
        console.log('success userBuddyRequestApproved ', res?.data)
        // buddiesList?.push(res?.data)
        // setbuddiesList(buddiesList)
        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request Approved',
          },
        ]
        setToasts(item)
        setreqAccepted(true)
        setTimeout(() => {
          props?.setshowNotification(false)
          // props?.setshowSearchResults(false)
        }, 2000)
        setTimeout(() => {
          // props?.setshowSearchResults(false)
          setreqAccepted(false)
        }, 4000)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestApproved ', err)
        // setbuddiesList(buddiesList)
        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request Approved failed',
            color: 'red',
          },
        ]
        setToasts(item)
        setreqAccepted(true)
        setTimeout(() => {
          props?.setshowNotification(false)
          // props?.setshowSearchResults(false)
        }, 2000)
        setTimeout(() => {
          // props?.setshowSearchResults(false)
          setreqAccepted(false)
        }, 4000)
      })
  }
  const denyBuddyReqFunc = (event) => {
    console.log('Reject buddy req', event?.currentTarget?.name)
    let obj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      requestFromUser: event?.currentTarget?.name?.split('@')[0],
      // "requestFromUser": event?.currentTarget?.name,
      requestTime: new Date().toISOString(),
    }
    UsersApis.userBuddyRequestDenied(obj)
      .then((res) => {
        console.log('success userBuddyRequestDenied ', res?.data)
        // buddiesList?.push(res?.data)
        // setbuddiesList(buddiesList)
        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request rejected',
          },
        ]
        setToasts(item)
        // let notification = approveBuddyReq(event?.currentTarget?.name)
        setreqRejected(true)
        setTimeout(() => {
          props?.setshowNotification(false)
          // props?.setshowSearchResults(false)
          setreqRejected(false)
        }, 2000)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestDenied ', err)
        // setbuddiesList(buddiesList)
        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request rejection failed',
            color: 'red',
          },
        ]
        setToasts(item)
        // let notification = approveBuddyReq(event?.currentTarget?.name)
        setreqRejected(true)
        setTimeout(() => {
          props?.setshowNotification(false)
          // props?.setshowSearchResults(false)
          setreqRejected(false)
        }, 2000)
      })
  }
  const viewAllNotificatrionClicked = (event) => {
    event.preventDefault()
    console.log(event?.currentTarget?.name)
    navigate('/notificationspage', { state: { notificationList1 } })
    props?.setshowNotification(false)

    // subscribePresence(event?.currentTarget?.name)
    // setShowSuccess(true)
    // setTimeout(() => {
    // props?.setshowSearchResults(false)
    // setShowSuccess(false)
    // }, 2000);
  }

  useEffect(() => {
    console.log('Alert updated notifications')
    if (!window?.connectionObj?.connected) {
      // window.connectionObj = null
      // loadChatClient()
    }
    props.setshowNotification(true)
    // let loogedInUser1 = JSON.parse(localStorage.getItem("user"));
    setnotificationList1(loogedInUser?.notificationsList)
  }, [reqAccepted, reqRejected]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { }, [loogedInUser?.notificationsList])

  useEffect(() => {
    let userObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    }
    setshowLoading(true)
    UsersApis.userGetNotification(userObj)
      .then((res) => {
        console.log('Res for userGetNotification', res)
        console.log('updated notification', [
          ...(notificationList1 || []),
          ...res.data,
        ])
        let mergedData = [
          ...(loogedInUser?.notificationsList || []),
          ...res.data,
        ]
        // eslint-disable-next-line
        //   mergedData = mergedData?.filter({
        //    return i?.userId
        // })

        setnotificationList1(mergedData)
      })
      .catch((err) => {
        console.error('Res for userGetNotification', err)
        let item = [
          {
            name: err?.response?.data?.errorDetail?.developerMessage,
            type: err?.response?.data?.errorDetail?.errorType,
          },
        ]
        setToasts(item)
        seterrorsForNotification(err?.response?.data)
        // setreqRejected(true)
        setTimeout(() => {
          // props?.setshowSearchResults(false)
          seterrorsForNotification(null)
        }, 5000)
      })
      .finally(() => {
        setshowLoading(false)
        console.log('Final list of notification', notificationList1)
      })
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (notificationList1?.length !== 0) {
      getUniqueNotifications(notificationList1)
    }
  }, [notificationList1])

  const getUniqueNotifications = (defaultNotifications = []) => {
    const uniqueNotifications = []

    const uniqueCreatedAtSet = new Set()

    for (let notifaction of defaultNotifications) {
      // const createdAt = notifaction?.createdAt;
      const checker = `${notifaction?.userId} ${notifaction?.notificationText}`
      if (!uniqueCreatedAtSet?.has(checker)) {
        uniqueCreatedAtSet?.add(checker)
        uniqueNotifications?.push(notifaction)
      }
    }
    // setFilteredNotifications();

    setFilteredNotifications(uniqueNotifications)
    //
  }

  function hrefReturnValue(value, item) {
    console.log(value, '..........//////')
    return (
      <strong key={value}>
        <Typography
          component={Link}
          className={'viewall'}
          to={
            item?.userCategory === 'Fleet Owner'
              ? `/fleetownerbuddyprofile/${item.userId}`
              : item?.userCategory === 'Driver'
                ? `/driverbuddyprofile/${item.userId}`
                : item?.userCategory === 'Own Operator'
                  ? `/owneroperatorbuddiesprofile/${item.userId}`
                  : ''
          }
        >
          {value}
          {'  '}
        </Typography>
      </strong>
    )
  }

  function textReturnValues(value) {
    return (
      <strong>
        {value}
        <br />
      </strong>
    )
  }

  function buttonReturnValues(value, item, buddyReqFunc) {
    const handleClick = (event) => {
      event.preventDefault() // Prevent default behavior
      buddyReqFunc(event)
    }
    return (
      <span
        key={value}
        style={{
          marginInlineEnd: '10px',
        }}
      >
        <button
          className={
            value === 'Approve'
              ? 'notify-accept'
              : value === 'Deny'
                ? 'notift-reject'
                : null
          }
          // onClick={buddyReqFunc}
          onClick={(event) => handleClick(event)}
          name={item?.jabberId}
        >
          {value}
        </button>
      </span>
    )
  }

  let notifactionTextDuplicate

  return (
    <>
      <ul
        className={`dropdown-menu messagenotifaction show `}
        style={{
          position: 'absolute',
          inset: '0px 0px auto auto',
          margin: 0,
          transform: 'translate3d(0px, 32.8px, 0px)',
        }}
      >
        <div
          style={{
            height: '400px',
            overflowY: 'scroll',
          }}
          className="Notification chat-list p-3"
        >
          <div className="d-flex justify-content-between py-3 ">
            <h3>
              <strong>Notifications</strong>
            </h3>
            {/* <a href="notifaction.html" className="viewall">View All</a> */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                onClick={() => {
                  props?.setshowNotification(false)
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '3%',
                  fontSize: 'large',
                  cursor: 'pointer',
                  margin: '-3px',
                  fontWeight: 'bolder',
                }}
              >
                x
              </span>
              {!showLoading && filteredNotifications?.length > 10 && (
                <Typography
                  // component={Link}
                  className={'viewall'}
                  // to="/notificationspage"
                  // color="textSecondary"
                  // variant="h6"
                  onClick={viewAllNotificatrionClicked}
                  sx={{
                    textDecoration: 'none',
                    fontSize: '1rem',
                    margin: '12px 0',
                  }}
                >
                  <span style={{ fontSize: 'larger' }}>ViewAll</span>{' '}
                </Typography>
              )}
            </div>
          </div>
          {showLoading && <Loader />}
          <div className="mainrow">
            <div className="looprow">
              <div className="status text-center">Today</div>
              {console.log(
                filteredNotifications,
                '.............../////////////////////////'
              )}
              {filteredNotifications?.map((item, i) => {
                // console.log("Notification", item, i);
                return (
                  <>
                    {/* {item?.type === "subscribe" ? (
                      <div className="w-100">
                        <strong>{item?.jid}</strong> sent you a friend request
                        <div className="d-flex gap my-3">
                          <button
                            className="notify-accept"
                            onClick={approveBuddyReqFunc}
                            name={item?.jid}
                          >
                            Accept{" "}
                          </button>
                          <button
                            className="notift-reject"
                            onClick={rejectBuddyReqFunc}
                            name={item?.jid}
                          >
                            Reject
                          </button>
                          {(reqAccepted || reqRejected) && (
                            <Loader
                              config={{
                                height: 20,
                                width: 20,
                                border: "3px solid #f79009",
                                borderTop: "3px solid #f2f4f7",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {item?.type === 'post' ? (
                      <Link to="#" className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <NotificationImg userId={item?.userId} />
                          <span className="active"></span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p>
                            <strong>Max Spinger</strong> liked your post: Lorem
                            ipsum dolor sit amet...
                          </p>
                        </div>
                      </Link>
                    ) : (
                      ''
                    )}
                    {item?.type === undefined && (
                      <div className="looprow">
                        <Link to="#" className="d-flex align-items-center">
                          <div
                            className="flex-shrink-0"
                            style={{ height: 50, width: 50 }}
                          >
                            <NotificationImg userId={item?.userId} />

                            <span className="active"></span>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            {item?.notificationText && (
                              <>
                                <strong>{item?.fullNameOfTheUser}</strong>
                                <div className="w-100">
                                  {checkerArray?.map((_, inx) => {
                                    if (
                                      item?.notificationText?.includes(
                                        `{${inx}}`
                                      )
                                    ) {
                                      if (notifactionTextDuplicate) {
                                        notifactionTextDuplicate =
                                          notifactionTextDuplicate?.map(
                                            (word) => {
                                              if (word === `{${inx}}`) {
                                                let argument =
                                                  item?.argumentList[inx]
                                                let typeOfArgument =
                                                  argument?.argumentType
                                                let argumentValue =
                                                  argument?.argumentValue

                                                let typeOfButtonOnclickName = `${argumentValue?.toLowerCase()}BuddyReqFunc`

                                                return (word =
                                                  typeOfArgument === 'href'
                                                    ? [
                                                      hrefReturnValue(
                                                        argumentValue,
                                                        item
                                                      ),
                                                    ]
                                                    : typeOfArgument === 'text'
                                                      ? [
                                                        textReturnValues(
                                                          argumentValue
                                                        ),
                                                      ]
                                                      : typeOfArgument ===
                                                        'button'
                                                        ? [
                                                          buttonReturnValues(
                                                            //passing value
                                                            argumentValue,
                                                            //passing item
                                                            item,
                                                            // when we put this `${argumentValue?.toLowerCase()}BuddyReqFunc`
                                                            //  it dont have actualfunction reference why becoz it is string.so i make changes
                                                            typeOfButtonOnclickName ===
                                                              'approveBuddyReqFunc'
                                                              ? approveBuddyReqFunc
                                                              : typeOfButtonOnclickName ===
                                                                'denyBuddyReqFunc'
                                                                ? denyBuddyReqFunc
                                                                : null
                                                          ),
                                                        ]
                                                        : null)
                                              }
                                              return word
                                            }
                                          )
                                      } else {
                                        notifactionTextDuplicate =
                                          item?.notificationText
                                            ?.split(' ')
                                            ?.map((word) => {
                                              if (word === `{${inx}}`) {
                                                let argument =
                                                  item?.argumentList[inx]
                                                let typeOfArgument =
                                                  argument?.argumentType
                                                let argumentValue =
                                                  argument?.argumentValue
                                                let typeOfButtonOnclickName = `${argumentValue?.toLowerCase()}BuddyReqFunc`

                                                return (word =
                                                  typeOfArgument === 'href'
                                                    ? [
                                                      hrefReturnValue(
                                                        argumentValue,
                                                        item
                                                      ),
                                                    ]
                                                    : typeOfArgument === 'text'
                                                      ? [
                                                        textReturnValues(
                                                          argumentValue
                                                        ),
                                                      ]
                                                      : typeOfArgument ===
                                                        'button'
                                                        ? [
                                                          buttonReturnValues(
                                                            //passing value
                                                            argumentValue,
                                                            //passing item
                                                            item,
                                                            //passing onclick functions
                                                            // when we put this `${argumentValue?.toLowerCase()}BuddyReqFunc`
                                                            //  it dont have actualfunction reference why becoz it is string.so i make changes
                                                            typeOfButtonOnclickName ===
                                                              'approveBuddyReqFunc'
                                                              ? approveBuddyReqFunc
                                                              : typeOfButtonOnclickName ===
                                                                'denyBuddyReqFunc'
                                                                ? denyBuddyReqFunc
                                                                : null
                                                          ),
                                                        ]
                                                        : null)
                                              }
                                              return word
                                            })
                                      }
                                    }
                                    return (
                                      checkerArray?.length - 1 === inx &&
                                      notifactionTextDuplicate?.map((word) =>
                                        Array.isArray(word) ? word : word + ' '
                                      )
                                    )
                                  })}
                                  {(notifactionTextDuplicate = undefined)}
                                </div>
                              </>
                            )}
                          </div>
                        </Link>
                      </div>
                    )}
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </ul>
      {reqAccepted && <Toasts toastsList={toasts} />}
      {reqRejected && <Toasts toastsList={toasts} />}
      {errorsForNotification && <Toasts toastsList={toasts} />}
    </>
  )
}

export default NotificationsPopUp

NotificationsPopUp.propTypes = {
  setshowNotification: PropTypes.func,
  showNotification: PropTypes.bool,
  props: PropTypes.object,
}
